























































import Vue from "vue";
import MyCroppie from '../directives/MyCroppie';
import * as Helpers from '../services/helpers';


const _size = (width, ratio) => ({ width, height: width / ratio })
const _size_px = ({ width, height }) => ({ width: `${width}px`, height: `${height}px` })

export default Vue.extend({
    components: { MyCroppie },
    props: ['value', 'name', 'opts'],
    data() {
        return {
            validity: { [this.name]: {} },
            val: this.value,
            toValidate: null, prev_val_before_croppie: null,
            error: null,
            withCroppie: false,
        };
    },
    computed: {
       ratio() {
           return this.opts.ratio || 4/5;
       },
       non_edit_size() {
           return _size_px(_size(100, this.ratio))
       },
       croppie_options() {
           return {
                init: {
                    viewport: _size(200, this.ratio),
                },
                export: {
                    type: 'base64',
                    format: 'jpeg',
                    size: _size(this.opts.width || 284, this.ratio),
                    quality: this.opts.photo_quality || 0.8,
                },
            };
       },
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            this.$emit('input', val);
        },
    },
    mounted() {
    },
    methods: {
        onInvalidPhoto() {
            const m = this.toValidate.match(/^data:(.*?);/);
            this.error = { mimeType: m && m[1] };
            this.cancel();
        },
        cancel() {
            this.toValidate = null;
            // give up on this uploaded value
            this.val_before_croppie = this.prev_val_before_croppie;
        },
        photoToValidate() {
            if (!this.val_before_croppie) this.val_before_croppie = this.val;
            this.toValidate = this.val_before_croppie;
            this.error = false;
        },
        async onPhotoUploaded(file) {
            this.prev_val_before_croppie = this.val_before_croppie || this.val
            this.val_before_croppie = await Helpers.fileReader('readAsDataURL', file)
            this.photoToValidate();
        },
        async croppieValidate() {
            this.val = await this.$refs.croppie.get(); 
            this.toValidate = null;
        },
        removePhoto() {
            this.val = null;
        }
    },
});
